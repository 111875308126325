
export default function Main(){
    return(
        <header>
            <h1>C:/user/ethan.rowe<span class="blink">_</span></h1>
            <nav>
                <a href="/home">Home</a>
                <span> / </span>
                <a href="../index">About</a>
                <span> / </span>
                <a href="../index">Projects</a>
            </nav>
        </header>
    );
}